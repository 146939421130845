<template>
  <div class="home p-20">
    <h1>Нүүр хуудас</h1>
    <el-row type="flex" class="row-bg m-t-20" justify="center" :gutter="20">
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="success">
          {{ statusCounter(100) }} - Хүргэгдсэн
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="warning">
          {{ statusCounter(60) }} - Жолоочид хувиарласан
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="info">
          {{ statusCounter(40) }} - Шинэ захиалга
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="danger">
          {{ statusCounter(10) }} - Цуцлагдсан
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" effect="dark" class="p-30 w-100" type="success">
          {{ checkPaymentCount(1) }} - Төлбөр төлөгдсөн
        </el-tag>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" effect="dark" class="p-30 w-100" type="info">
          {{ checkPaymentCount(0) }} - Төлөгдөөгүй
        </el-tag>
      </el-col>
    </el-row>
    <h5 class="m-t-10">Захиалгын тоо суваг</h5>
    <el-row type="flex" class="row-bg m-t-20" justify="center" :gutter="20">
       <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="success">
          {{ checkChannel("web") }} - веб
        </el-tag>
      </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="warning">
          {{ checkChannel("cc") }} - дуудлагын төв
        </el-tag>
      </el-col>
         <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
        <el-tag shadow="always" class="p-30 w-100" type="info">
          {{ checkChannel("chat") }} - чат
        </el-tag>
      </el-col>
    </el-row>
     <el-row>
        <el-col :span="23" class="home_body m-t-20">
          <NewUserCount />
        </el-col>
      </el-row>
    <el-row>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="home_body m-t-20">
        <BarChartPos />
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="home_body m-t-20">
        <h4>Хамгийн өндөр дүнтэй захиалга хийсэн хэрэглэгчдийн жагсаалт</h4>
        <el-card class="m-t-20">
          <el-input v-model="search" clearable placeholder="Утасны дугаараар хайх" />
          <ul class="infinite-list p-10 " style="overflow:auto">
            <li
              v-for="i, index in userOrderListCount.filter(data => !search || data.customer_phone.toLowerCase().includes(search.toLowerCase()))"
              :key="index" class="infinite-list-item">
              <span>{{ index + 1 }} - </span>
              <span> {{ i.customer_phone }}</span>
              <span class="m-l-40">дүн: {{ i.total_amount }}</span>
              <span class="m-l-40">З/тоо: {{ i.count }}</span>
            </li>
          </ul>
        </el-card>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import services from '@/helpers/services.js'
import moment from 'moment'
import BarChartPos from '@/components/charts/BarChartPos.vue'
import NewUserCount from '@/components/charts/NewUserCount.vue'
export default {

  components: {
    BarChartPos,
    NewUserCount
  },
  computed: mapGetters({
    companyInfoGetter: 'companyInfoGetter'
  }),
  data () {
    return {
      tabledata: [],
      now: new Date(),
      startDate: moment().startOf('month').set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      }).add(8, 'hour'),
      endDate: moment().add(1, 'day'),
      userOrderListCount: [],
      loading: false,
      search: '',
      windowWidth: window.innerWidth
    }
  },
  created () {
    this.getView()
    this.userOrderListCountFunction()
  },
  methods: {
    userOrderListCountFunction () {
      const payload = {
        company_name: this.companyInfoGetter.name
      }
      services.getUserOrderCount(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.userOrderListCount = response.result
            this.loading = false
          }
        })
    },
    checkPaymentCount (param) {
      let count = 0
      this.tabledata.forEach(element => {
        if (element.is_paid === param && this.userOrderListCount.company_name === this.companyInfoGetter.name) {
          count = count + 1
        }
      })
      return count
    },
    checkChannel (payload) {
      let count = 0
      this.tabledata.forEach(element => {
        if (element.order_channel === payload) {
          count = count + 1
        }
      })
      return count
    },
    statusCounter (status) {
      let count = 0
      this.tabledata.forEach(element => {
        if (window.location.hostname === 'localhost') {
          if (element.order_status === status) {
            count = count + 1
          }
        } else if (window.location.hostname === 'eruulhun.com') {
          if (element.order_status === status) {
            count = count + 1
          }
        } else if (window.location.hostname === 'iteever.mn') {
          if (element.order_status === status) {
            count = count + 1
          }
        }
      })
      return count
    },
    getView () {
      const payload = {
        start_date: this.startDate,
        end_date: this.endDate,
        company_name: this.companyInfoGetter.name
      }
      services.orderList(payload)
        .then((response) => {
          if (response.status === 'success') {
            this.tabledata = response.result
            this.loading = false
          }
        })
    }
  }
}

</script>
<style scoped>
  .infinite-list {
    float: left;
    height: 400px;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .infinite-list .infinite-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: var(--el-color-primary-light-9);
    margin: 5px;
    color: var(--el-color-primary);
  }

  .infinite-list .infinite-list-item+.list-item {
    margin-top: 5px;
  }

</style>
